export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  WEBSOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,
  GETNOTES: "getnotes",
  SUPERADMINLOGIN: "superadminlogin",

  //ORGANIZATION
  INSERTORGANIZATION: "insertorganization",
  GETORGANIZATION: "getorganization",
  UPDATEORGANIZATION: "updateorganization",
  DELETEORGANIZATION: "deleteorganization",
  UPDATESTATUS: "updatestatus",

  //STORE
  INSERTSTORE: "insertstoredata",
  GETSTOREDETAILS: "getstoredetails",
  UPDATESTORE: "updatestoredata",
  UPDATESTORESTATUS: "updatestorestatus",
  GETEXPIREDSTOREDETAILS: "getexpiredstoredetails",
  COUNTORGANIZATIONANDSTOREEXPIRE: "countorganizationandexpirestore",

  //NOTIFICATION
  GETNOTIFICATION: "getnotification",
  READNOTIFICATION: "readnotification",

  //PACKAGE
  INSERTPACKAGE: "insertpackage",
  GETPACKAGELISTWITHPAGINATION: "getpackagewithpagination",
  UPDATEPACKAGE: "updatepackage",
  DELETEPACKAGE: "deletepackage",
  GETPACKAGELIST: "getpackagelist",
  ORGANIZATIONPACKAGEPURCHASE: "organizationpackagepurchase",
};
